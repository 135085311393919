var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "a-card",
        { staticClass: "card", attrs: { title: "仓库管理", bordered: false } },
        [
          _c("repository-form", {
            ref: "repository",
            attrs: { showSubmit: false },
          }),
        ],
        1
      ),
      _c(
        "a-card",
        { staticClass: "card", attrs: { title: "任务管理", bordered: false } },
        [_c("task-form", { ref: "task", attrs: { showSubmit: false } })],
        1
      ),
      _c("a-card", [
        _c(
          "form",
          { attrs: { autoFormCreate: (form) => (this.form = form) } },
          [
            _c("a-table", {
              attrs: {
                columns: _vm.columns,
                dataSource: _vm.data,
                pagination: false,
              },
              scopedSlots: _vm._u(
                [
                  _vm._l(["name", "workId", "department"], function (col, i) {
                    return {
                      key: col,
                      fn: function (text, record, index) {
                        return [
                          record.editable
                            ? _c("a-input", {
                                key: col,
                                staticStyle: { margin: "-5px 0" },
                                attrs: {
                                  value: text,
                                  placeholder: _vm.columns[i].title,
                                },
                                on: {
                                  change: (e) =>
                                    _vm.handleChange(
                                      e.target.value,
                                      record.key,
                                      col
                                    ),
                                },
                              })
                            : [_vm._v(_vm._s(text))],
                        ]
                      },
                    }
                  }),
                  {
                    key: "operation",
                    fn: function (text, record, index) {
                      return [
                        record.editable
                          ? [
                              record.isNew
                                ? _c(
                                    "span",
                                    [
                                      _c(
                                        "a",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.saveRow(record.key)
                                            },
                                          },
                                        },
                                        [_vm._v("添加")]
                                      ),
                                      _c("a-divider", {
                                        attrs: { type: "vertical" },
                                      }),
                                      _c(
                                        "a-popconfirm",
                                        {
                                          attrs: { title: "是否要删除此行？" },
                                          on: {
                                            confirm: function ($event) {
                                              return _vm.remove(record.key)
                                            },
                                          },
                                        },
                                        [_c("a", [_vm._v("删除")])]
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "span",
                                    [
                                      _c(
                                        "a",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.saveRow(record.key)
                                            },
                                          },
                                        },
                                        [_vm._v("保存")]
                                      ),
                                      _c("a-divider", {
                                        attrs: { type: "vertical" },
                                      }),
                                      _c(
                                        "a",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.cancel(record.key)
                                            },
                                          },
                                        },
                                        [_vm._v("取消")]
                                      ),
                                    ],
                                    1
                                  ),
                            ]
                          : _c(
                              "span",
                              [
                                _c(
                                  "a",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggle(record.key)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _c("a-divider", {
                                  attrs: { type: "vertical" },
                                }),
                                _c(
                                  "a-popconfirm",
                                  {
                                    attrs: { title: "是否要删除此行？" },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.remove(record.key)
                                      },
                                    },
                                  },
                                  [_c("a", [_vm._v("删除")])]
                                ),
                              ],
                              1
                            ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            }),
            _c(
              "a-button",
              {
                staticStyle: {
                  width: "100%",
                  "margin-top": "16px",
                  "margin-bottom": "8px",
                },
                attrs: { type: "dashed", icon: "plus" },
                on: { click: _vm.newMember },
              },
              [_vm._v("新增成员")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "footer-tool-bar",
        [
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.validate },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }